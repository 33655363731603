import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../components/Button.jsx';
import setSpacesInText from '../../functions/setSpacesInText';
import Link from '../../components/Link.jsx';

class IndexHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { components, user } = this.props;
        const products = components
            ? Object.keys(components.products)
                  .filter((key) => !Number.isNaN(+key))
                  .map((key) => components.products[key])
            : [];
        const specialPrizes = components?.specialPrizes;

        return (
            <>
                <div ref={this.parent} className={`indexPrizes ${components ? '_ready' : ''}`}>
                    <div className="indexPrizes__inner">
                        <div className="indexPrizes__title">
                            {components?.products?.header?.title}
                        </div>
                        <div className="indexPrizes__partners">
                            {products.map((product, key) => (
                                <a
                                    href={product.url}
                                    className="indexPrizes__partner"
                                    key={key}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={product.thumb}
                                        alt=""
                                        className="indexPrizes__partnerImage"
                                    />
                                </a>
                            ))}
                        </div>
                        <div className="indexPrizes__title">{specialPrizes?.header?.title}</div>
                        <p
                            className="indexPrizes__description"
                            dangerouslySetInnerHTML={{
                                __html: setSpacesInText(specialPrizes?.header?.description),
                            }}
                        ></p>
                        <div className="indexPrizes__cards">
                            <div className="indexPrizes__card">
                                <div className="indexPrizes__cardHead">
                                    <img
                                        src={specialPrizes?.prize1?.thumb}
                                        alt=""
                                        className="indexPrizes__cardImage"
                                    />
                                    <div className="indexPrizes__cardSupport _garant">
                                        {specialPrizes?.prize1?.description}
                                    </div>
                                </div>
                                <div
                                    className="indexPrizes__cardContent"
                                    dangerouslySetInnerHTML={{
                                        __html: setSpacesInText(specialPrizes?.prize1?.title),
                                    }}
                                ></div>
                            </div>
                            <div className="indexPrizes__card">
                                <div className="indexPrizes__cardHead">
                                    <img
                                        src={specialPrizes?.prize2?.thumb}
                                        alt=""
                                        className="indexPrizes__cardImage"
                                    />
                                    <div className="indexPrizes__cardSupport _top">
                                        {specialPrizes?.prize2?.description}
                                    </div>
                                </div>
                                <div
                                    className="indexPrizes__cardContent"
                                    dangerouslySetInnerHTML={{
                                        __html: setSpacesInText(specialPrizes?.prize2?.title),
                                    }}
                                ></div>
                            </div>
                            <div className="indexPrizes__card">
                                <div className="indexPrizes__cardHead">
                                    <img
                                        src={specialPrizes?.prize3?.thumb}
                                        alt=""
                                        className="indexPrizes__cardImage"
                                    />
                                    <div className="indexPrizes__cardSupport">
                                        {specialPrizes?.prize3?.description}
                                    </div>
                                    <div className="indexPrizes__cardCounter">
                                        <span>50</span> шт
                                    </div>
                                </div>
                                <div
                                    className="indexPrizes__cardContent"
                                    dangerouslySetInnerHTML={{
                                        __html: setSpacesInText(specialPrizes?.prize3?.title),
                                    }}
                                ></div>
                            </div>
                        </div>
                        <Link
                            className="indexPrizes__button"
                            href={user ? 'profile/upload' : 'login'}
                        >
                            <Button className="_white _pulsation">
                                {specialPrizes?.button?.title}
                            </Button>
                        </Link>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(IndexHeader);

IndexHeader.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    components: PropTypes.object,
};
