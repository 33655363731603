import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../scss/main.scss';

import { dispatcher } from '../redux/redux';

import saveJWT from '../functions/saveJWT';
import { getCookie, setCookie } from '../functions/handlerCookies';
import checkAuth from '../functions/checkAuth';
import getCurrentPage from '../functions/getCurrentPage';
import changePage from '../functions/changePage';

import Pages from '../components/Pages.jsx';
import TopBar from '../components/TopBar.jsx';
import MobileMenu from '../components/MobileMenu.jsx';
import Cookies from '../components/Cookies.jsx';
import CuponPopup from '../components/CuponPopup.jsx';

import Index from './Index.jsx';
import Animate from '../components/Animate.jsx';
import WaitHeader from '../components/WaitHeader.jsx';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerAncor = this.handlerAncor.bind(this);
        this.setScroll = this.setScroll.bind(this);
        this.visibilityDocChange = this.visibilityDocChange.bind(this);

        this.parent = React.createRef();
    }

    waitMode = true;

    pages = {
        index: {
            render() {
                return (
                    <>
                        <Index setScroll={this.setScroll} />
                    </>
                );
            },
        },
    };

    visibilityDocChange() {
        if (!document.hidden) {
            checkAuth();
        }

        document.dispatchEvent(
            new CustomEvent('visibilityChange', { detail: { active: !document.hidden } }),
        );
    }

    handlerAncor({ detail: { name, changeIsHard } }) {
        const { storePages } = this.props;
        const currentPage = getCurrentPage({ pages: storePages, filter: (page) => !page.level });

        if (currentPage !== 'index') {
            dispatcher({ type: 'indexAncor', data: name });
            changePage({ href: '', changeIsHard });
            dispatcher({ type: 'mobileMenuShow', data: false });
        } else {
            document.dispatchEvent(new CustomEvent('indexScroll', { detail: { name } }));
        }
    }

    setScroll(scrollTop) {
        const { device, storePages } = this.props;
        const currentPage = getCurrentPage({
            pages: storePages,
            filter: (page) => !page.isPopup && !page.level,
        });
        const stopValue = device === 'mobile' || currentPage !== 'index' ? 1 : 1;

        if (scrollTop >= stopValue && !this.state.topBarActive) {
            this.setState({ topBarActive: true });
        }

        if (scrollTop < stopValue && this.state.topBarActive) {
            this.setState({ topBarActive: false });
        }
    }

    componentDidMount() {
        document.addEventListener('indexAncor', this.handlerAncor);
        document.addEventListener('visibilitychange', this.visibilityDocChange);

        const rootJWT = document.querySelector('#root').getAttribute('data-jwt');

        if (rootJWT) {
            setCookie(process.env.REACT_APP_HASH, rootJWT);
        }

        saveJWT(getCookie(process.env.REACT_APP_HASH));

        checkAuth();

        window.getJWT = () => getCookie(process.env.REACT_APP_HASH);
        window.saveJWT = saveJWT;
        window.apiUrl = process.env.REACT_APP_API;
    }

    render() {
        const { topBarActive } = this.state;
        const { windowIsReady, device, storePages, cuponPopup } = this.props;
        const currentPage = getCurrentPage({
            pages: storePages,
            filter: (page) => !page.isPopup && !page.level,
        });
        const currentPopupPage = getCurrentPage({
            pages: storePages,
            filter: (page) => page.isPopup,
        });

        return (
            <>
                <div
                    ref={this.parent}
                    className={`body__contentInner ${this.waitMode ? '_waitMode' : ''}`}
                >
                    {this.waitMode ? (
                        <>
                            <div className="body__pages">
                                <div className="body__page _show">
                                    <WaitHeader />
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {device === 'mobile' && (
                                <>
                                    <MobileMenu />
                                </>
                            )}
                            <TopBar isActive={topBarActive} isInner={currentPage !== 'index'} />
                            <Cookies />
                            <Animate
                                className="body__popupBack"
                                isShow={!!currentPopupPage || cuponPopup?.isShow}
                            />

                            <Animate className="body__popup" isShow={cuponPopup?.isShow}>
                                <CuponPopup />
                            </Animate>

                            {windowIsReady && (
                                <Pages
                                    className="body__pages"
                                    classNamePage="body__page"
                                    filter={(page) => !page.level}
                                    pages={this.pages}
                                    context={this}
                                />
                            )}
                        </>
                    )}
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        storePages: state.pages,
        windowIsReady: state.windowIsReady,
        user: state.user,
        cuponPopup: state.cuponPopup,
    };
}

export default connect(mapStateToProps)(App);

App.propTypes = {
    device: PropTypes.string,
    windowIsReady: PropTypes.bool,
    storePages: PropTypes.object,
    user: PropTypes.object,
    cuponPopup: PropTypes.object,
};
