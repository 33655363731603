import axios from 'axios';
// import getHeaders from './getHeaders';
import requestSuccess from './requestSuccess';
import getHeaders from './getHeaders';

export default function getContent({ name }) {
    if (!name) {
        return Promise.reject();
    }

    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_API}/api/content/${name}/`, {
                headers: getHeaders(),
            })
            .then(
                (res) => {
                    requestSuccess(res);

                    resolve(res.data);
                },
                () => {
                    reject();
                },
            );
    });
}
