const pages = require('../redux/pages').default;

export default function getNav({ user }) {
    const navOrder = ['index', 'gallery', 'winners', 'faq', 'profile'];
    const ancors = {
        rules: {
            content: 'Правила',
        },
        prizes: {
            content: 'Призы',
        },
    };

    return navOrder.map((name) => {
        const info = pages.find((page) => page.name === name) || ancors[name];
        const isAncor = !!ancors[name] || name === 'index';
        let isPopup = info?.isPopup;

        if (name === 'profile' && !user) {
            name = 'login';
            isPopup = true;
        }

        return { name, content: info?.content, isAncor, isPopup };
    });
}
