import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import changePage from '../functions/changePage';
import { dispatcher } from '../redux/redux';
import setSpacesInText from '../functions/setSpacesInText';
// import setSpacesInText from '../functions/setSpacesInText';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { waitMode, mainContent } = this.props;
        const footer = mainContent?.footer;
        // const testMenu = [
        //     { title: 'Правила акции' },
        //     { title: 'Политика конфиденциальности' },
        //     { title: 'Задать вопрос' },
        // ];
        const menu = [
            { title: footer?.url1?.title, key: 'feedback' },
            { title: footer?.url2?.title, url: footer?.url2?.url },
            { title: footer?.url3?.title, url: footer?.url3?.url },
        ];

        return (
            <>
                <div ref={this.parent} className="footer">
                    <div className="footer__inner">
                        {!waitMode && (
                            <div className="footer__nav">
                                {menu.map((link) => {
                                    const Tag = link.url ? 'a' : 'div';
                                    const tagProps = link.url
                                        ? { href: link.url, target: '_blank' }
                                        : link.key === 'feedback'
                                        ? {
                                              onClick: () => {
                                                  dispatcher({
                                                      type: 'ancorToFaqForm',
                                                      data: true,
                                                  }).then(() => {
                                                      changePage({ href: 'faq' });
                                                  });
                                              },
                                          }
                                        : {};

                                    return (
                                        <div className="footer__navItem" key={link.title}>
                                            <Tag {...tagProps} className="footer__navLink">
                                                {link.title}
                                            </Tag>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        <p
                            className="footer__description"
                            dangerouslySetInnerHTML={{
                                __html: setSpacesInText(
                                    footer?.disclaimer?.description ||
                                        'Общий срок проведения акции с 08.05.2024 г. по 12.07.2024 г. включительно (включая срок выдачи призов). Период приема заявок для участия в акции с 08.05.2024 г. по 04.06.2024 г. включительно. Количество призов ограничено. Внешний вид призов может отличаться от представленного. Полную информацию об организаторе акции, правилах проведения, количестве призов, сроках, месте и порядке их получения можно получит на сайте murafon.ru, а также в мобильном приложении Магнит: акции и доставка (6+). Есть ограничения. АО "Тандер", ОГРН 1022301598549, 350002, Краснодарский край, г. Краснодар, ул. им. Леваневского, д.185.',
                                ),
                            }}
                        ></p>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        mainContent: state.mainContent,
    };
}

export default connect(mapStateToProps)(Footer);

Footer.propTypes = {
    device: PropTypes.string,
    waitMode: PropTypes.string,
    mainContent: PropTypes.object,
};
