import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../components/Button.jsx';
import Link from '../../components/Link.jsx';
import setSpacesInText from '../../functions/setSpacesInText';

class IndexHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.visibilityChange = this.visibilityChange.bind(this);

        this.parent = React.createRef();
    }

    intervalId;

    startGalery() {
        const { components } = this.props;
        const album = components?.howto?.album;

        this.setState((state) => {
            const newState = { ...state };
            const galery = [...(newState.galery || new Array(8).fill({}).map((item, key) => key))];

            galery.forEach((item, key) => {
                if (item < album.thumbs.length - 1) {
                    galery[key] += 1;
                } else {
                    galery[key] = 0;
                }
            });

            newState.galery = galery;

            return newState;
        });
    }

    initGalery(force) {
        const { components } = this.props;

        if (components && (!this.isInit || force)) {
            this.isInit = true;

            clearInterval(this.intervalId);

            if (!force) {
                this.startGalery();
            }

            this.intervalId = setInterval(() => {
                this.startGalery();
            }, 3_000);
        }
    }

    visibilityChange({ detail: { active } }) {
        if (!active) {
            clearInterval(this.intervalId);
        } else {
            this.initGalery(true);
        }
    }

    componentDidMount() {
        this.initGalery();

        document.addEventListener('visibilityChange', this.visibilityChange);
    }

    componentDidUpdate() {
        this.initGalery();
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);

        document.removeEventListener('visibilityChange', this.visibilityChange);
    }

    render() {
        const { galery = [] } = this.state;
        const { components, user } = this.props;
        const content = components?.howto;

        return (
            <>
                <div
                    ref={this.parent}
                    className={`indexHeader ${components ? '_init' : ''}`}
                    id="index"
                >
                    <div className="indexHeader__inner">
                        <div className="indexHeader__content">
                            <div className="indexHeader__contentInner">
                                <div className="indexHeader__cards">
                                    <div className="indexHeader__card">
                                        <div className="indexHeader__cardTitle">
                                            {content?.step1?.title}
                                        </div>
                                        <p
                                            className="indexHeader__cardDescription"
                                            dangerouslySetInnerHTML={{
                                                __html: setSpacesInText(
                                                    content?.step1?.description,
                                                ),
                                            }}
                                        ></p>
                                    </div>
                                    <div className="indexHeader__card">
                                        <div className="indexHeader__cardTitle">
                                            {content?.step2?.title}
                                        </div>
                                        <p
                                            className="indexHeader__cardDescription"
                                            dangerouslySetInnerHTML={{
                                                __html: setSpacesInText(
                                                    content?.step2?.description,
                                                ),
                                            }}
                                        ></p>
                                    </div>
                                    <div className="indexHeader__card">
                                        <div className="indexHeader__cardTitle">
                                            {content?.step3?.title}
                                        </div>
                                        <p
                                            className="indexHeader__cardDescription"
                                            dangerouslySetInnerHTML={{
                                                __html: setSpacesInText(
                                                    content?.step3?.description,
                                                ),
                                            }}
                                        ></p>
                                    </div>
                                    <div className="indexHeader__card">
                                        <div className="indexHeader__cardTitle">
                                            {content?.step4?.title}
                                        </div>
                                        <p
                                            className="indexHeader__cardDescription"
                                            dangerouslySetInnerHTML={{
                                                __html: setSpacesInText(
                                                    content?.step4?.description,
                                                ),
                                            }}
                                        ></p>
                                    </div>
                                </div>
                                <Link
                                    className="indexHeader__button"
                                    href={user ? 'profile/upload' : 'login'}
                                >
                                    <Button className="_white _pulsation">{content?.button?.title}</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="indexHeader__galery">
                        <div className="indexHeader__galeryItems">
                            {new Array(8).fill({}).map((item, key) => {
                                const currentKey = galery[key];

                                return (
                                    <div className="indexHeader__galeryItem" key={key}>
                                        <div className="indexHeader__galeryCard">
                                            {components?.howto?.album?.thumbs?.map(
                                                (image, imageKey) => (
                                                    <img
                                                        src={image}
                                                        alt=""
                                                        className={`indexHeader__galeryCardImage ${
                                                            currentKey === imageKey ? '_show' : ''
                                                        }`}
                                                        key={imageKey}
                                                    />
                                                ),
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <Link className="indexHeader__galeryButton" href="gallery">
                            <Button className="_purple _mediumSize _pulsation">галерея фото</Button>
                        </Link>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(IndexHeader);

IndexHeader.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    components: PropTypes.object,
};
