import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from './Button.jsx';
import Link from './Link.jsx';

import getNav from '../functions/getNav';
import { dispatcher } from '../redux/redux';
import changePage from '../functions/changePage';
import getCurrentPage from '../functions/getCurrentPage';
import logout from '../functions/logout';
import handlerLoading from '../functions/handlerLoading';
import handlerPopup from '../functions/handlerPopup';

class TopBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderButton = this.renderButton.bind(this);

        this.parent = React.createRef();
    }

    keys = {
        index: 'main',
        rules: 'rules',
        about: 'about',
        prizes: 'prizes',
        winners: 'winners',
        faq: 'faq',
    };

    getButtons() {
        const { user, pages } = this.props;
        const currentPage = getCurrentPage({ pages, filter: (page) => !page.isPopup });

        if (currentPage === 'anket' && user?.status === 'ANKET_REQUIRED') {
            return [{ key: 'logout' }];
        }

        if (user?.status === 'EMAIL_CONFIRM_REQUIRED') {
            return [{ key: 'logout' }];
        }

        return [{ key: 'tg' }, { key: 'code' }, { key: 'profile' }];
    }

    renderButton({ prop: key }) {
        const { loadingKey } = this.state;
        const { user, mainContent } = this.props;
        const buttons = mainContent?.header?.buttons;

        if (key === 'logout') {
            return (
                <div className="topBar__button">
                    <Button
                        className="_red"
                        onClick={() => {
                            handlerLoading.call(this, 'logout').then(() => {
                                logout().then(() => {
                                    handlerLoading.call(this, null);
                                });
                            });
                        }}
                        loader={loadingKey === 'logout'}
                    >
                        Выход
                    </Button>
                </div>
            );
        }

        if (key === 'tg') {
            return (
                <a
                    className="topBar__button"
                    href={buttons?.button1?.url}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button className="_emptyBlue">
                        {buttons?.button1?.title || 'чат-бот телеграм'}
                    </Button>
                </a>
            );
        }

        if (key === 'code') {
            return (
                <div
                    className="topBar__button"
                    onClick={() => {
                        if (user) {
                            handlerPopup({ name: 'codePopup', isShow: true });
                        } else {
                            changePage({ href: 'login' });
                        }
                    }}
                >
                    <Button className="_red">
                        {buttons?.button2?.title || 'зарегистрировать код'}
                    </Button>
                </div>
            );
        }

        return (
            <Link href={user ? 'profile' : 'login'} className="topBar__button">
                <Button className="_white">{buttons?.button3?.title || 'личный кабинет'}</Button>
            </Link>
        );
    }

    checkReady() {
        const { mainContent } = this.props;

        if (mainContent?.header && !this.isReady) {
            this.isReady = true;

            this.setState({ isInit: true });
        }
    }

    componentDidMount() {
        this.checkReady();
    }

    componentDidUpdate() {
        this.checkReady();
    }

    render() {
        const { mobileMenuShow, mainContent, isActive, isInner, user } = this.props;

        return (
            <div className={`body__topBar ${isActive ? '_active' : ''}`}>
                <div
                    className={`topBar ${isActive || isInner ? '_active' : ''} ${
                        !isActive && isInner ? '_inner' : ''
                    }`}
                >
                    <div className="topBar__inner">
                        <div className="topBar__block _logo">
                            <div
                                className="topBar__logo"
                                onClick={() => {
                                    document.dispatchEvent(
                                        new CustomEvent('indexAncor', {
                                            detail: { name: 'index' },
                                        }),
                                    );

                                    dispatcher({
                                        type: 'mobileMenuShow',
                                        data: false,
                                    });
                                }}
                            >
                                {/* <img
                                    src={require('../media/logo-W.png')}
                                    alt=""
                                    className="topBar__logoIcon"
                                /> */}
                            </div>
                        </div>
                        <div
                            className={`topBar__menu ${mobileMenuShow ? '_active' : ''}`}
                            onClick={() => {
                                dispatcher({ type: 'mobileMenuShow', data: !mobileMenuShow });
                            }}
                        >
                            <div className="topBar__menuItem"></div>
                            <div className="topBar__menuItem"></div>
                            <div className="topBar__menuItem"></div>
                        </div>
                        <div className="topBar__block _nav">
                            <div className="topBar__nav">
                                {getNav({ user }).map((item) => {
                                    const { name, isAncor } = item;
                                    const LinkTag = isAncor ? 'div' : Link;
                                    const linkProps = isAncor
                                        ? {
                                              onClick: () => {
                                                  document.dispatchEvent(
                                                      new CustomEvent('indexAncor', {
                                                          detail: { name },
                                                      }),
                                                  );
                                              },
                                          }
                                        : { pageName: name };
                                    const key = this.keys[name];
                                    const menuObj = mainContent.header?.menu || {};
                                    const menu = Object.keys(menuObj).map(
                                        (itemKey) => menuObj[itemKey],
                                    );
                                    const content =
                                        menu?.find((menuItem) => menuItem.url === key)?.title ||
                                        item.content;

                                    return (
                                        <div
                                            className={`topBar__navItem ${content ? '_ready' : ''}`}
                                            key={name}
                                            data-key={name}
                                        >
                                            <LinkTag className="topBar__navLink" {...linkProps}>
                                                {content}
                                            </LinkTag>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="topBar__block _logo">
                            <a
                                className="topBar__logo _magnit"
                                href="https://magnit.ru"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {/* <img
                                    src={require('../media/logo-M.png')}
                                    alt=""
                                    className="topBar__logoIcon"
                                /> */}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        mobileMenuShow: state.mobileMenuShow,
        mainContent: state.mainContent,
        user: state.user,
        pages: state.pages,
    };
}

export default connect(mapStateToProps)(TopBar);

TopBar.propTypes = {
    mobileMenuShow: PropTypes.bool,
    mainContent: PropTypes.object,
    user: PropTypes.object,
    pages: PropTypes.object,
    isActive: PropTypes.bool,
    isInner: PropTypes.bool,
};
