const pages = [
    {
        links: ['404'],
        name: '404',
        content: '404',
    },
    {
        links: ['', undefined],
        name: 'index',
        content: 'Главная',
    },
];

export default pages;
